<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6" align-self="end">
          <h3 class="title text-capitalize">{{ $t("system settings") }}</h3>
        </v-col>
      </v-row>
      <v-divider class="mb-4"></v-divider>
      <v-layout row wrap>
        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  <v-icon left>mdi-chart-pie</v-icon
                  >{{ $t("dashboard graphs") }}
                </div>

                <v-list-item-subtitle
                  >{{
                    $t("graphs give you an insight about you stock and sales")
                  }}.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-switch
                :loading="loading"
                v-model="graphs"
                :color="$store.state.secondaryColor"
                @change="updateDashboardSettings()"
              ></v-switch>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-store-clock</v-icon>{{ $t("payment time") }}
                </div>

                <v-list-item-subtitle
                  >{{
                    $t("at what point should a payment be made")
                  }}?</v-list-item-subtitle
                >
                <v-radio-group v-model="paymentTime" row>
                  <v-radio
                    :label="$t('before service')"
                    value="Before Service"
                  ></v-radio>
                  <v-radio
                    :label="$t('after service')"
                    value="After Service"
                  ></v-radio>
                </v-radio-group>
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="savePaymentTime()"
                  :loading="paymentButtonLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-alert</v-icon>{{ $t("stock alert") }}
                </div>

                <v-list-item-subtitle>
                  {{ $t("get an alert when stock goes below the set number") }}
                  <v-text-field
                    v-model="stockQuantityNotification"
                    dense
                    outlined
                    :rules="rules.emptyField"
                    @keypress="digits"
                  ></v-text-field
                ></v-list-item-subtitle>
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="saveQuantityNotification"
                  :loading="buttonLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>

        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-cash-register</v-icon>{{ $t("waiter") }}
                </div>

                <v-list-item-subtitle
                  >{{
                    $t("will the waiter also serve as a cashier")
                  }}?</v-list-item-subtitle
                >
                <v-radio-group v-model="waiterIsCashier" row>
                  <v-radio :label="$t('yes')" value="true"></v-radio>

                  <v-radio :label="$t('no')" value="false"></v-radio>
                </v-radio-group>
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="saveWaiterIsCashier()"
                  :loading="waiterIsCashierButtonLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md6 lg4 class="pa-2">
          <v-card>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-1">
                  <v-icon left>mdi-cellphone-check</v-icon
                  >{{ $t("mpesa paybill") }}
                </div>

                <v-list-item-subtitle>
                  {{ $t("set your mpesa paybill") }}
                  <v-text-field
                    v-model="paybill"
                    dense
                    outlined
                    :rules="rules.emptyField"
                    @keypress="digits"
                  ></v-text-field
                ></v-list-item-subtitle>
                <v-btn
                  small
                  dark
                  class="gradients"
                  :color="$store.state.secondaryColor"
                  @click="savePaybill"
                  :loading="paybillSaveLoading"
                >
                  <v-icon small left>save</v-icon>
                  <span class="caption text-capitalize font-weight-bold">{{
                    $t("save")
                  }}</span>
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-flex>
      </v-layout>

      <v-snackbar
        v-model="snackSuccessWaiterIsCashier"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("waiter duties updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessWaiterIsCashier = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorWaiterIsCashier"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not update waiter duties. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorWaiterIsCashier = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccessPaymentTime"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("payment time setting updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessPaymentTime = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorPaymentTime"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not update payment time. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorPaymentTime = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorQuantityNotification"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not update quantity. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorQuantityNotification = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccessQuantityNotification"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("quantity notification setting updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessQuantityNotification = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackError"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not update graph status. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackError = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorConfig"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("could not get dashboard status. Try again later") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorConfig = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccess"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("dashboard graph setting updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccess = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackSuccessPaybill"
        top
        centered
        color="success lighten-5 success--text text--darken-3"
        :timeout="4000"
      >
        <span>{{ $t("paybill number updated") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="success darken-3"
            @click="snackSuccessPaybill = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackErrorPaybill"
        top
        centered
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{
          $t("could not update paybill number. Try again later")
        }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="error darken-3"
            @click="snackErrorPaybill = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import db from "@/plugins/fb";
import { digits } from "@/plugins/functions";
export default {
  data() {
    return {
      graphs: "",
      paymentTime: "",
      waiterIsCashier: "",
      snackError: false,
      snackSuccess: false,
      loading: true,
      buttonLoading: false,
      paymentButtonLoading: false,
      waiterIsCashierButtonLoading: false,
      snackErrorConfig: false,
      snackSuccessQuantityNotification: false,
      snackSuccessPaybill: false,
      snackErrorPaybill: false,
      snackSuccessPaymentTime: false,
      snackErrorPaymentTime: false,
      snackErrorQuantityNotification: false,
      snackErrorWaiterIsCashier: false,
      snackSuccessWaiterIsCashier: false,
      stockQuantityNotification: "",
      paybill: "",
      paybillSaveLoading: false,
    };
  },
  computed: {
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },
  created() {
    this.currentUser = this.$store.state.currentUser;
    this.getSettings();
  },
  methods: {
    digits,
    getSettings() {
      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.graphs = snaps.data().dashboardGraphs;
            this.paymentTime = snaps.data().paymentTime;
            this.waiterIsCashier = snaps.data().waiterIsCashier.toString();
            this.stockQuantityNotification =
              snaps.data().stockQuantityNotification;
            this.paybill = snaps.data().paybill;
            this.loading = false;
          }
        })
        .catch(() => {
          this.snackErrorConfig = true;
        });
      // ######
    },
    updateDashboardSettings() {
      this.loading = true;

      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            this.graphs = snaps.data().dashboardGraphs;

            //convert to oposite of the current setting
            if (this.graphs == true) {
              this.graphs = false;
            } else {
              this.graphs = true;
            }

            db.collection("configurations")
              .doc(this.currentUser)
              .update({
                dashboardGraphs: this.graphs,
              })
              .then(() => {
                this.loading = false;
                this.snackSuccess = true;
                this.getSettings();
              })
              .catch(() => {
                this.loading = false;
                this.snackError = true;
              });
          }
        })
        .catch(() => {
          this.snackErrorConfig = true;
        });
    },
    saveQuantityNotification() {
      this.buttonLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          stockQuantityNotification: this.stockQuantityNotification,
        })
        .then(() => {
          this.buttonLoading = false;
          this.snackSuccessQuantityNotification = true;
          this.getSettings();
        })
        .catch(() => {
          this.buttonLoading = false;
          this.snackErrorQuantityNotification = true;
        });
    },
    savePaymentTime() {
      this.paymentButtonLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          paymentTime: this.paymentTime,
        })
        .then(() => {
          this.paymentButtonLoading = false;
          this.snackSuccessPaymentTime = true;
          this.getSettings();
        })
        .catch(() => {
          this.paymentButtonLoading = false;
          this.snackErrorPaymentTime = true;
        });
    },
    savePaybill() {
      this.paybillSaveLoading = true;
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          paybill: this.paybill,
        })
        .then(() => {
          this.paybillSaveLoading = false;
          this.snackSuccessPaybill = true;
          this.getSettings();
        })
        .catch(() => {
          this.paybillSaveLoading = false;
          this.snackErrorPaybill = true;
        });
    },

    saveWaiterIsCashier() {
      var dataFormat;
      this.waiterIsCashierButtonLoading = true;

      //format the data from string to bool
      if (this.waiterIsCashier == "true") {
        dataFormat = true;
      } else if (this.waiterIsCashier == "false") {
        dataFormat = false;
      }
      db.collection("configurations")
        .doc(this.currentUser)
        .update({
          waiterIsCashier: dataFormat,
        })
        .then(() => {
          this.waiterIsCashierButtonLoading = false;
          this.snackSuccessWaiterIsCashier = true;
          this.getSettings();
        })
        .catch(() => {
          this.waiterIsCashierButtonLoading = false;
          this.snackErrorWaiterIsCashier = true;
        });
    },
  },
};
</script>
